import styled from '@emotion/styled';
import { Box } from '@mui/material';

const StyledContentComponents = {
  StyledSubList: styled(Box)({
    maxWidth: '630px',
    marginLeft: '20px',
    marginBottom: '30px'
  }),
  StyledFirstStringList: styled(Box)({
    maxWidth: '610px',
    marginBottom: '20px',
    marginLeft: '20px'
  })
};

export default StyledContentComponents;
