import styled from '@emotion/styled';
import { Box } from '@mui/material';

const StyledApp = styled(Box)({
  minHeight: '100dvh',
  backgroundColor: '#fdfde8',
  paddingTop: '64px'
});

export default StyledApp;
